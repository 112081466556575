import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  AttributionControl,
} from "react-leaflet";
import { Params, useLoaderData } from "react-router-dom";
import { getBranch } from "../../services/data/branch-data-service";
import { Branch } from "../../services/data/branches";
import logo from "../../logo.jpg";
import styled from "styled-components";
import { FaUber, FaMapLocationDot, FaBusSimple, FaWaze } from "react-icons/fa6";
import { OpeningTimesTable } from "../../components/opening-times-table";
import {
  citymapperURL,
  uberURL,
  googleMapsURL,
  wazeURL,
} from "../../utils/url-generation";
import { Footer } from "../../components/footer";
import { Button } from "../../elements/button";
import { ButtonGrid } from "../../elements/button-grid";
import { ShareWidget } from "../../components/share-widget";
import { useEffect } from "react";
import { Update } from "../../components/update";

export function BranchRoute() {
  const data = useLoaderData() as { branch: Branch };

  useEffect(() => {
    document.title = `IDMJI Branch Finder | ${data.branch.name}`;
  }, [data]);

  return (
    <Root>
      <Header>
        <Logo src={logo} alt="IDMJI logo" />
        <div>
          <h1>{data.branch?.name}</h1>
          {data.branch.address ? <div>{data.branch.address}</div> : null}
          {data.branch.postCode ? (
            <div style={{ whiteSpace: "nowrap", fontWeight: "bold" }}>
              {data.branch.postCode}
            </div>
          ) : null}
        </div>
        {!!data.branch.next ? (
          <Alert>
            <div>
              The next&nbsp;{data.branch.next.service.toLocaleLowerCase()}
              &nbsp;service will take place on&nbsp;
              <b>
                {new Date(data.branch.next.date).toLocaleString("en-GB", {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour12: true,
                  hour: "numeric",
                })}
              </b>
            </div>
          </Alert>
        ) : null}
      </Header>
      {data?.branch?.coords?.latitude && data?.branch?.coords?.latitude && (
        <Section>
          <SectionContent>
            <H3>How to get there</H3>
            <Map>
              <MapContainer
                center={{
                  lat: data.branch.coords.latitude,
                  lng: data.branch.coords.longitude,
                }}
                zoom={15}
                scrollWheelZoom={false}
                style={{ height: "100%" }}
                attributionControl={false}
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <AttributionControl position="topright" />
                <Marker
                  position={[
                    data.branch.coords.latitude,
                    data.branch.coords.longitude,
                  ]}
                >
                  <Popup autoClose={false}>
                    <H3>{data.branch.name}</H3>
                    <p>{data.branch.address}</p>
                  </Popup>
                </Marker>
              </MapContainer>
            </Map>
            <ButtonGrid>
              <Button
                as="a"
                href={googleMapsURL(
                  data.branch.coords.latitude,
                  data.branch.coords.longitude
                )}
                rel="noopener noreferrer"
                target="_blank"
              >
                <FaMapLocationDot size="18px" />
                Google Maps
              </Button>
              <Button
                as="a"
                href={uberURL(
                  data.branch.coords.latitude,
                  data.branch.coords.longitude,
                  data.branch.address
                )}
                rel="noopener noreferrer"
                target="_blank"
              >
                <FaUber size="18px" />
                Uber
              </Button>
              <Button
                as="a"
                href={citymapperURL(
                  data.branch.coords.latitude,
                  data.branch.coords.longitude,
                  data.branch.address
                )}
                rel="noopener noreferrer"
                target="_blank"
              >
                <FaBusSimple size="18px" />
                Citymapper
              </Button>
              <Button
                as="a"
                href={wazeURL(
                  data.branch.coords.latitude,
                  data.branch.coords.longitude
                )}
                rel="noopener noreferrer"
                target="_blank"
              >
                <FaWaze size="18px" />
                Waze
              </Button>
            </ButtonGrid>
          </SectionContent>
        </Section>
      )}
      {!!data.branch.openingTimes ? (
        <Section>
          <SectionContent>
            <H3>Opening Times</H3>
            <OpeningTimesTable openingTimes={data.branch.openingTimes} />
          </SectionContent>
        </Section>
      ) : null}
      {!!data.branch.updates.length && (
        <Section>
          <SectionContent>
            <H3>Updates and Notes</H3>
            {data.branch.updates.map((update, index) => {
              return (
                <Update
                  key={index}
                  message={update.message}
                  date={update.date}
                />
              );
            })}
          </SectionContent>
        </Section>
      )}
      <Section>
        <SectionContent>
          <H3>Share</H3>
          <ShareWidget />
        </SectionContent>
      </Section>
      <Footer />
    </Root>
  );
}

export function loader({ params }: { params: Params }) {
  if (!params.id) throw new Error("missing param");

  const branch = getBranch(params.id);

  if (!branch) throw new Error("not found");

  return { branch };
}

const Logo = styled.img`
  width: 80%;
`;

const Map = styled.section`
  position: relative;
  width: 100%;
  height: 40vh;
  max-height: 600px;
  min-height: 250px;
  z-index: 10;
`;

const Root = styled.div`
  position: relative;
  display: grid;
  margin: 0 auto;

  > *:nth-child(even) {
    background-color: var(--bg-200);
  }
`;

const Section = styled.section``;

const SectionContent = styled.div`
  padding: 3rem 2rem;
  display: grid;
  gap: 1.5rem;
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
`;

const Header = styled.header`
  padding: 3rem 2rem;
  text-align: center;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
`;

const H3 = styled.h3`
  color: var(--text-200);
  text-align: center;
`;

const Alert = styled.div`
  background-color: #fee;
  border: 1px solid #a66;
  border-radius: 0.5rem;
  color: #a66;
  padding: 1rem;
  text-align: left;
`;
