import styled from "styled-components";

export function Footer() {
  return (
    <Root>
      2023 © Church of God Ministry of Jesus Christ International - All rights
      reserved
    </Root>
  );
}

export const Root = styled.footer`
  font-size: 0.8rem;
  color: var(--text-200);
  text-align: center;
  padding: 2rem;
  font-weight: bold;
`;
