import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import { RootRoute } from "./routes/root/root-route";
import { ErrorRoute } from "./routes/error/error-route";
import {
  BranchRoute,
  loader as branchLoader,
} from "./routes/branch/branch-route";
import { CountryRouteGbr } from "./routes/country/country-route";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootRoute />,
    errorElement: <ErrorRoute />,
    children: [
      {
        path: "branch/:id",
        element: <BranchRoute />,
        loader: branchLoader,
      },
      {
        path: "country/gbr",
        element: <CountryRouteGbr />,
      },
      {
        index: true,
        element: <Navigate to="/country/gbr" />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
