export function uberURL(
  latitude: number,
  longitude: number,
  address: string
): string {
  const params = new URLSearchParams({
    "dropoff[latitude]": String(latitude),
    "dropoff[longitude]": String(longitude),
    "dropoff[formatted_address]": address,
  })
    .toString()
    .replace(/%5D/g, "]")
    .replace(/%5B/g, "[");

  return `https://m.uber.com/ul?action=setPickup&${params}`;
}

export function citymapperURL(
  lat: string | number,
  lng: string | number,
  address: string
) {
  const q = new URLSearchParams({
    endcoord: encodeURI(lat + "," + lng),
    endaddress: address,
  }).toString();

  return `https://citymapper.com/directions?${q}`;
}

export function googleMapsURL(lat: string | number, lng: string | number) {
  const params = new URLSearchParams({ q: `${lat},${lng}` }).toString();
  return `https://maps.google.com?${params}`;
}

export function wazeURL(lat: string | number, lng: string | number) {
  const params = new URLSearchParams({ ll: `${lat},${lng}` }).toString();

  return `https://www.waze.com/ul?${params}`;
}
