import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa6";
import styled from "styled-components";
import dayjs from "dayjs";

export function Update(props: {
  message: string;
  date: string | number | Date;
}) {
  return (
    <Root>
      <FaQuoteLeft style={{ color: "var(--bg-300)", marginRight: "0.1rem" }} />
      {props.message}
      <FaQuoteRight style={{ color: "var(--bg-300)", marginLeft: "0.1rem" }} />
      <Date>{dayjs(props.date).format("DD MMM YYYY")}</Date>
    </Root>
  );
}

const Root = styled.blockquote`
  position: relative;
  padding: 2.7rem 2rem 2rem;
  background-color: var(--bg-200);
  font-size: 1.4rem;
  font-weight: 200;
  border-radius: 1.5rem;
  border: 1px solid var(--bg-300);
  border-bottom-right-radius: 0;
  line-height: 1.4;

  &:nth-child(2n) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 1.5rem;
  }
`;

const Date = styled.div`
  padding: 1rem;
  color: var(--text-200);
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.9rem;
  border-top-right-radius: 1rem;
`;
