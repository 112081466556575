export interface Branch {
  id: string;
  name: string;
  address: string;
  postCode: string;
  coords?: {
    longitude: number;
    latitude: number;
  };
  updates: { message: string; date: string | number | Date }[];
  openingTimes?: Record<string, { service: string; time: string }>;
  next?: { service: string; date: string | number | Date };
}

export const branchMap = new Map<string, Branch>([
  [
    "2b090266-3e2c-4f60-934e-b5d3c10644b6",
    {
      id: "2b090266-3e2c-4f60-934e-b5d3c10644b6",
      name: "Tottenham Hale",
      address: "Unit 14, Mill Mead Industrial Centre, Mill Mead Road",
      postCode: "N17 9QU",
      coords: {
        latitude: 51.59237378,
        longitude: -0.05688638481,
      },
      openingTimes: {
        monday: {
          service: "Worship",
          time: "7:00PM",
        },
        tuesday: {
          service: "Bible Study",
          time: "7:00PM",
        },
        wednesday: {
          service: "Worship",
          time: "7:00PM",
        },
        thursday: {
          service: "Teaching",
          time: "7:00PM",
        },
        friday: {
          service: "Worship",
          time: "7:00PM",
        },
        saturday: {
          service: "Bible Study",
          time: "6:00PM",
        },
        sunday: {
          service: "Teaching",
          time: "10:00AM",
        },
      },
      updates: [],
    },
  ],

  [
    "4c8884b2-8b78-48c0-8e81-b78d73b871c5",
    {
      id: "4c8884b2-8b78-48c0-8e81-b78d73b871c5",
      name: "Birmingham",
      address: "Tudor Grange Academy, Dingle Lane, Solihull",
      postCode: "B91 3PD",
      coords: {
        latitude: 52.40422547,
        longitude: -1.792462887,
      },
      openingTimes: {
        saturday: {
          service: "Bible Study",
          time: "4PM Fortnightly",
        },
      },
      updates: [
        {
          message:
            "Teachings are now fortnightly from Oct 5th. For more information please call +447502432648.",
          date: 1727489447144,
        },
      ],
    },
  ],

  [
    "05bcc786-5265-416b-9755-556cadf7297e",
    {
      id: "05bcc786-5265-416b-9755-556cadf7297e",
      name: "East Dulwich",
      address: "Bassano Street, East Dulwich, London",
      postCode: "SE22 8RU",
      coords: {
        latitude: 51.45678803,
        longitude: -0.07667362689,
      },
      openingTimes: {
        monday: {
          service: "Worship",
          time: "7:00PM",
        },
        tuesday: {
          service: "Bible Study",
          time: "7:00PM",
        },
        wednesday: {
          service: "Worship",
          time: "7:00PM",
        },
        thursday: {
          service: "Teaching",
          time: "7:00PM",
        },
        friday: {
          service: "Worship",
          time: "7:00PM",
        },
        saturday: {
          service: "Bible Study",
          time: "6:00PM",
        },
        sunday: {
          service: "Teaching",
          time: "10:00AM",
        },
      },
      updates: [],
    },
  ],

  [
    "1ba3e08a-6167-4028-8535-183f3017730a",
    {
      id: "1ba3e08a-6167-4028-8535-183f3017730a",
      name: "Canning Town",
      address: "33 Lanrick Road, London",
      postCode: "E14 0FE",
      coords: {
        latitude: 51.51442027,
        longitude: 0.001964461756,
      },
      openingTimes: {
        monday: {
          service: "Worship",
          time: "7:00PM",
        },
        tuesday: {
          service: "Bible Study",
          time: "7:00PM",
        },
        wednesday: {
          service: "Worship",
          time: "7:00PM",
        },
        thursday: {
          service: "Teaching",
          time: "7:00PM",
        },
        friday: {
          service: "Worship",
          time: "7:00PM",
        },
        saturday: {
          service: "Bible Study",
          time: "6:00PM",
        },
        sunday: {
          service: "Teaching",
          time: "10:00AM",
        },
      },
      updates: [],
    },
  ],

  [
    "b5a8272b-2750-4d30-9e1d-07717194908b",
    {
      id: "b5a8272b-2750-4d30-9e1d-07717194908b",
      name: "Croydon",
      address: "3 Rd Floor, 4 Bedford Park, Croydon",
      postCode: "CR0 2AP",
      coords: {
        latitude: 51.37883698463812,
        longitude: -0.09735860016534235,
      },
      openingTimes: {
        monday: {
          service: "Bible Study",
          time: "7:00PM",
        },
        tuesday: {
          service: "Worship",
          time: "7:00PM",
        },
        wednesday: {
          service: "Teaching",
          time: "7:00PM",
        },
        thursday: {
          service: "Worship",
          time: "7:00PM",
        },
        friday: {
          service: "Worship",
          time: "7:00PM",
        },
        saturday: {
          service: "Bible Study",
          time: "7:00PM",
        },
        sunday: {
          service: "Teaching",
          time: "10:00AM",
        },
      },
      updates: [],
    },
  ],

  [
    "41dbf2fc-bbb2-4125-aadf-661f4dcd0963",
    {
      id: "41dbf2fc-bbb2-4125-aadf-661f4dcd0963",
      name: "Edinburgh",
      address: "Dalry 183 Creative Cultural Hub, 183 Dalry Road, Edinburgh",
      postCode: "EH11 2EB",
      coords: {
        latitude: 55.86298231,
        longitude: -3.1201,
      },
      openingTimes: {
        Saturday: {
          service: "Teaching",
          time: "2:00PM (Every two months)",
        },
      },
      updates: [
        {
          message:
            "Teachings are happening on a Saturday every two months from Oct 19th. For more information please call +447502432648.",
          date: 1727489447144,
        },
      ],
    },
  ],

  [
    "c0cfdd0d-c368-4d82-97ec-a56a73fbf0d3",
    {
      id: "c0cfdd0d-c368-4d82-97ec-a56a73fbf0d3",
      name: "Oxford",
      address: "36 Derwent Ave, Oxford",
      postCode: "OX3 0AP",
      coords: {
        latitude: 51.7676,
        longitude: -1.228,
      },
      openingTimes: {
        Saturday: {
          service: "Bible Study / Teaching",
          time: "4:00PM",
        },
      },
      updates: [],
    },
  ],

  [
    "a23de16c-9ca7-4be9-a0f1-94810fff6a8a",
    {
      id: "a23de16c-9ca7-4be9-a0f1-94810fff6a8a",
      name: "Hammersmith",
      address: "Hammersmith Academy, 25 Cathnor Road, London",
      postCode: "W12 9JD",
      coords: {
        latitude: 51.50201095,
        longitude: -0.23585946565,
      },
      openingTimes: {
        monday: {
          service: "Worship",
          time: "7:00PM",
        },
        tuesday: {
          service: "Bible Study",
          time: "7:00PM",
        },
        wednesday: {
          service: "Worship",
          time: "7:00PM",
        },
        thursday: {
          service: "Teaching",
          time: "7:00PM",
        },
        friday: {
          service: "Worship",
          time: "7:00PM",
        },
        saturday: {
          service: "Bible Study",
          time: "6:00PM",
        },
        sunday: {
          service: "Teaching",
          time: "3:00PM",
        },
      },
      updates: [],
    },
  ],

  [
    "b7a60d8f-9ce2-4727-83bb-e8410ed6d49f",
    {
      id: "b7a60d8f-9ce2-4727-83bb-e8410ed6d49f",
      name: "Guildford",
      address: "Sandfield Primary School, Stoke Road, Guildford",
      postCode: "GU1 4DT",
      coords: {
        latitude: 51.23944397,
        longitude: -0.5718273273,
      },
      openingTimes: {
        thursday: {
          service: "Bible Study",
          time: "7:00PM",
        },
        saturday: {
          service: "Worship",
          time: "6:00PM",
        },
        sunday: {
          service: "Teaching",
          time: "10:00AM",
        },
      },
      updates: [],
    },
  ],

  [
    "699a41ce-e12e-4108-ba1e-8093fdb76066",
    {
      id: "699a41ce-e12e-4108-ba1e-8093fdb76066",
      name: "Bristol",
      address: "Cabot Court, Bragg's Lane",
      postCode: "BS2 0AX",
      coords: {
        latitude: 51.4576752,
        longitude: -2.5807371,
      },
      openingTimes: {
        friday: {
          service: "Bible Study",
          time: "6:00PM",
        },
        saturday: {
          service: "Teaching (Once a month)",
          time: "2:00PM",
        },
        sunday: {
          service: "Teaching",
          time: "10:00AM",
        },
      },
      updates: [
        {
          message:
            "For information about the time and location of the next service, call +447706627478.",
          date: 1611670800000,
        },
      ],
    },
  ],

  [
    "7ce2e641-1f6a-4c94-afc7-e5f741df315c",
    {
      id: "7ce2e641-1f6a-4c94-afc7-e5f741df315c",
      name: "Manchester",
      address: "Lindale Road, Worsley, Greater Manchester",
      postCode: "M28 1BG",
      coords: {
        latitude: 53.5060158,
        longitude: -2.4330118,
      },
      openingTimes: {
        saturday: {
          service: "Bible Study",
          time: "10am",
        },
      },
      updates: [
        {
          message:
            "For information about the time and location of the next service, call +447502432648.",
          date: "2024-03-02",
        },
      ],
    },
  ],

  [
    "2d1960b8-bf5f-4b5c-9426-70a120a75854",
    {
      id: "2d1960b8-bf5f-4b5c-9426-70a120a75854",
      name: "Southampton",
      address: "31 Palmerston Road",
      postCode: "SO14 1LL",
      coords: {
        latitude: 50.90637374,
        longitude: -1.401103135,
      },
      openingTimes: {
        monday: {
          service: "Worship",
          time: "7:00PM",
        },
        tuesday: {
          service: "Worship",
          time: "7:00PM",
        },
        wednesday: {
          service: "Bible Study",
          time: "7:00PM",
        },
        friday: {
          service: "Worship",
          time: "7:00PM",
        },
        sunday: {
          service: "Teaching",
          time: "10:00AM",
        },
      },
      updates: [],
    },
  ],
]);

export const branches = Array.from(branchMap.values()).sort((a, b) =>
  a.name > b.name ? 1 : -1
);
