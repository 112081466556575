import QRcode from "qrcode";
import { useLayoutEffect, useMemo, useRef } from "react";
import { FaWhatsapp, FaCopy } from "react-icons/fa6";
import copy from "copy-to-clipboard";
import { Button } from "../elements/button";
import { ButtonGrid } from "../elements/button-grid";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

export const CanvasWrapper = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
`;

const Root = styled.div`
  display: grid;
  gap: 1.5rem;
`;

export function ShareWidget() {
  const location = useLocation();
  const canvasRef = useRef(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const url = useMemo(() => window.location.toString(), [location]);

  useLayoutEffect(() => {
    if (canvasRef.current)
      QRcode.toCanvas(canvasRef.current, url, {
        margin: 0,
        color: {
          dark: "#1d1c1c",
          light: "#f5f4f1",
        },
        width: 200,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Root>
      <CanvasWrapper>
        <canvas ref={canvasRef} style={{ margin: "0 auto" }} />
      </CanvasWrapper>
      <ButtonGrid>
        <Button onClick={() => copy(url)}>
          <FaCopy size="18px" />
          Copy Link
        </Button>
        <Button
          onClick={() =>
            window.open(
              `https://wa.me?text=${encodeURI(`${document.title} ${url}`)}`,
              "_blank",
              "noopener noreferrer"
            )
          }
        >
          <FaWhatsapp size="18px" />
          WhatsApp
        </Button>
      </ButtonGrid>
    </Root>
  );
}
