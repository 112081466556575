import styled, { css } from "styled-components";

export const buttonCSS = css`
  background-color: var(--bg-300);
  padding: 0.8rem 1rem;
  border-radius: 0.4rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--bg-100);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1) inset;
  cursor: pointer;
  text-decoration: none;
  color: var(--text-100);
  font-weight: 600;
  line-height: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.9rem;

  &:hover,
  &:active,
  &:focus {
    background-color: var(--bg-200);
  }
`;

export const compactButtonCSS = css<{ compact?: boolean }>`
  ${(p) =>
    p.compact &&
    css`
      padding: 0.4rem 0.5rem;
    `}
`;

export const Button = styled.button<{ compact?: boolean }>`
  ${buttonCSS}
  ${compactButtonCSS}
`;
