import { FaBars, FaHouse, FaXmark } from "react-icons/fa6";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import { Button } from "../../elements/button";
import { useLayoutEffect, useState } from "react";
import { ShareWidget } from "../../components/share-widget";
import { Footer } from "../../components/footer";
import logo from "../../logo.jpg";

export function RootRoute() {
  const [isNavOpen, setNavOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useLayoutEffect(() => {
    try {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } catch (_) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  useLayoutEffect(() => {
    document.body.style.position = "static";

    if (window.innerWidth < 400) {
      const id = setTimeout(() => {
        const scroll = document.body.scrollTop;
        document.body.style.position = isNavOpen ? "fixed" : "static";
        document.body.scrollTop = scroll;
      }, 200);

      return () => clearTimeout(id);
    }
  }, [isNavOpen]);

  return (
    <Root>
      <Header>
        <Link to="/">
          <Logo src={logo} alt="IDMJI logo" />
        </Link>
        <Heading>IDMJI Branch Finder</Heading>
        <Button onClick={() => setNavOpen(!isNavOpen)} compact>
          <FaBars size="20px" />
        </Button>
      </Header>
      <Outlet />
      <SidePanel visible={isNavOpen}>
        <CloseButton onClick={() => setNavOpen(false)} compact>
          <FaXmark size="20px" />
        </CloseButton>
        <Button
          onClick={() => {
            navigate("/");
            setNavOpen(false);
          }}
        >
          <FaHouse size="20px" />
          Homepage
        </Button>
        <ShareWidget />
        <Footer />
      </SidePanel>
    </Root>
  );
}

const CloseButton = styled(Button)`
  && {
    position: absolute;
    right: 1rem;
    top: 1rem;
  }
`;

const Root = styled.div``;

const Logo = styled.img`
  height: 35px;
`;

const Heading = styled.h1`
  font-size: 1rem;
`;

const Header = styled.header`
  background-color: var(--bg-200);
  width: 100%;
  padding: 0.5rem 1rem;
  display: grid;
  grid-template-columns: max-content auto max-content;
  align-items: center;
  gap: 1rem;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid var(--bg-300);
  border-top: 1px solid var(--bg-200);
  position: sticky;
  z-index: 200;
  top: 0;
  line-height: 1.2;
`;

const SidePanel = styled.aside<{ visible: boolean }>`
  background-color: var(--bg-200);
  max-width: 400px;
  width: 100%;
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  height: 100dvh;
  min-height: -webkit-fill-available;
  overflow: scroll;
  z-index: 300;
  transform: translateX(100%);
  visibility: hidden;
  opacity: 0;
  transition: transform 200ms ease-in, opacity 1ms ease-in 200ms,
    visibility 1ms linear 200ms;
  pointer-events: none;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  padding: 4rem 3rem 3rem;
  display: grid;
  align-items: flex-start;
  grid-template-columns: 1fr;
  grid-template-rows: max-content auto max-content;
  gap: 2rem;

  ${(p) =>
    p.visible &&
    css`
      transform: translateX(0);
      visibility: visible;
      opacity: 1;
      transition: transform 200ms ease-out 2ms, opacity 1ms linear,
        visibility 1ms linear;
      pointer-events: initial;
    `};
`;
