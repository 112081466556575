import { Link } from "react-router-dom";
import { branches } from "../../services/data/branches";
import {
  AttributionControl,
  MapContainer,
  Marker,
  TileLayer,
  Popup,
} from "react-leaflet";
import styled, { css } from "styled-components";
import { buttonCSS } from "../../elements/button";
import { Footer } from "../../components/footer";
import { useEffect } from "react";

export function CountryRouteGbr() {
  useEffect(() => {
    document.title = "IDMJI Branch Finder | United Kingdom";
  }, []);

  return (
    <Root>
      <Map>
        <MapContainer
          center={{
            lat: 53.3811,
            lng: -1.47,
          }}
          zoom={5}
          scrollWheelZoom={false}
          style={{ height: "100%" }}
          attributionControl={false}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <AttributionControl position="topright" />
          {branches
            .map((b) => {
              if (!b.coords) return null;

              return (
                <Marker
                  key={b.id}
                  position={[b.coords.latitude, b.coords.longitude]}
                >
                  <Popup autoClose={false}>
                    <Item key={b.id} slim>
                      <div>
                        <h2>{b.name}</h2>
                        <div>
                          <small>
                            {b.address}&nbsp;-&nbsp;
                            <b style={{ whiteSpace: "nowrap" }}>{b.postCode}</b>
                          </small>
                        </div>
                      </div>
                      <CustomLink to={"/branch/" + b.id}>Open</CustomLink>
                    </Item>
                  </Popup>
                </Marker>
              );
            })
            .filter(Boolean)}
        </MapContainer>
      </Map>
      <Heading>Branches in the United Kingdom:</Heading>
      <Section>
        <List>
          {branches.map((b) => {
            return (
              <Item as="li" key={b.id}>
                <ItemContent>
                  <h3>{b.name}</h3>
                  {b.address ? <div>{b.address}</div> : null}
                  {b.postCode ? (
                    <div style={{ whiteSpace: "nowrap", fontWeight: "bold" }}>
                      {b.postCode}
                    </div>
                  ) : null}
                </ItemContent>
                <CustomLink to={"/branch/" + b.id}>Open</CustomLink>
              </Item>
            );
          })}
        </List>
        <Footer />
      </Section>
    </Root>
  );
}

const ItemContent = styled.div`
  display: grid;
  font-size: 0.9rem;
`;

const Heading = styled.h2`
  padding: 0.9rem;
  font-size: 0.9rem;
  font-weight: bold;
  background-color: var(--text-200);
  color: white;
`;

const Section = styled.section`
  display: grid;
  gap: 0.5rem;
`;

const CustomLink = styled(Link)`
  ${buttonCSS}
`;

const Map = styled.div`
  height: 50vh;
  min-height: 200px;
  max-height: 600px;
  z-index: 100;
`;

const Item = styled.div<{ slim?: boolean }>`
  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: auto max-content;
  gap: 1rem;
  padding: 2rem;

  ${(p) =>
    p.slim &&
    css`
      padding: 0.4rem;
    `}
`;

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  border: 1px solid var(--bg-200);

  > ${Item} {
    background-color: var(--bg-200);

    &:nth-child(2n) {
      background-color: var(--bg-100);
    }
  }
`;

const Root = styled.div`
  min-height: 100vh;
  min-height: 100svh;
  display: grid;
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
`;
