import styled from "styled-components";

export function OpeningTimesTable(props: {
  openingTimes: Record<
    string,
    {
      service: string;
      time: string;
    }
  >;
}) {
  return (
    <Wrapper>
      <Table>
        <thead>
          <TableRow style={{ background: "var(--bg-300)" }}>
            <HeadingCell>Day</HeadingCell>
            <HeadingCell>Service</HeadingCell>
            <TimeHeadingCell>Time</TimeHeadingCell>
          </TableRow>
        </thead>
        <tbody>
          {Object.entries(props.openingTimes).map(
            ([day, { service, time }]) => (
              <OpeningTime key={day} day={day} service={service} time={time} />
            )
          )}
        </tbody>
      </Table>
    </Wrapper>
  );
}

function OpeningTime(props: { day: string; service: string; time: string }) {
  return (
    <TableRow>
      <DayCell>{props.day}</DayCell>
      <TableCell>{props.service}</TableCell>
      <TimeCell>{props.time}</TimeCell>
    </TableRow>
  );
}

const Wrapper = styled.div`
  overflow: auto;
  padding: 0;
  margin: 0;
  border: 0;
`;

const TableRow = styled.tr`
  &:nth-child(2n) {
    background-color: var(--bg-100);
  }
`;

const TableCell = styled.td`
  padding: 1rem 0.8rem;
`;

const DayCell = styled(TableCell)`
  font-weight: bold;
  text-transform: capitalize;
`;

const TimeCell = styled(TableCell)`
  text-align: right;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  border: 1px solid var(--bg-300);
  border-radius: 4px;
  font-size: 0.889rem;
  padding: 0;
  margin: 0;
`;

const HeadingCell = styled(TableCell)`
  font-weight: bold;
  text-transform: capitalize;
`;

const TimeHeadingCell = styled(HeadingCell)`
  text-align: right;
`;
